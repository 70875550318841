import RegisterConfirm from 'components/RegisterConfirm';
import IndexLayout from 'layouts';
import React from 'react';
import { Components } from 'sitedata';

export default () => {
  return (
    <IndexLayout>
      <Components.Header />
      <RegisterConfirm />
      <Components.Footer />
    </IndexLayout>
  );
};
